import { AnalyticsEventAction } from '@arkadium/modules/dist/lib/Analytics/constants/AnalyticsContants';
import { TLink, WEB_COMPONENTS_NAMES } from '@/libs/utils';
import { UIKIT_CONSTS } from '@/components/UiKit/UiKitConnector';
import { renderMarkup as renderMarkupHeroBig } from '@/uikit/components/ark-ui-block-card-hero-big/_index';
import { renderMarkup as renderMarkupStandard } from '@/uikit/components/ark-ui-block-card-standard/_index';
import { renderMarkup as renderMarkupHeroSeo } from '@/uikit/components/ark-ui-block-card-hero-seo/_index';
import { renderMarkup as renderMarkupHeroMarketing } from '@/uikit/components/ark-ui-block-card-hero-marketing/_index';
import { renderMarkup as renderMarkupNumeric } from '@/uikit/components/ark-ui-block-card-mod-numeric/_index';
import { renderMarkup as renderMarkupPortrait } from '@/uikit/components/ark-ui-block-card-portrait/_index';

export enum TCardType {
	HERO_BIG = 'Big Hero',
	HERO_MKT = 'Marketing',
	HERO_SEO = 'SEO', // heroCarousel, 'Standard' can also be included into it
	STANDARD = 'Standard', // standardCarousel
	NUMERIC = 'Numeric', // numericCarousel
	FEATURED = 'Featured', // featuredCarousel
	FEATURED_SMALL = 'Featured Small', // featuredSmallCarousel
	PORTRAIT = 'Portrait', // portraitCarouseL
}

export enum TCarouselType {
	HERO = 'heroCarousel', // hero cards, standard cards
	STANDARD = 'standardCarousel', // standard cards only
	NUMERIC = 'numericCarousel', // numeric cards only
	FEATURED = 'featuredCarousel', // featured cards only
	FEATURES_SMALL = 'featuredSmallCarousel', // featured small cards only
	UNKNOWN = 'unknown', // fallback
	UNHANDLED = 'carouselMixedRulesBreak', // mixed - rules for CM are broken
	PORTRAIT = 'portraitCarouseL', // Portrait cards only
}

export type SplideOptions = {
	type?: 'slide' | 'loop' | 'fade';
	role?: string; //default is 'region';
	label?: string;
	labelledby?: string;
	rewind?: boolean; //default is false;
	speed?: number; //default is 400;
	rewindSpeed?: number;
	rewindByDrag?: boolean;
	width?: number | string;
	height?: number | string;
	fixedWidth?: number | string;
	fixedHeight?: number | string;
	heightRatio?: number;
	autoWidth?: boolean; //default is false;
	autoHeight?: boolean; //default is false;
	start?: number; //default is 0;
	perPage?: number; //default is 1;
	perMove?: number;
	clones?: number;
	cloneStatus?: boolean; //default is true;
	focus?: number | 'center';
	gap?: number | string;
	padding?:
		| number
		| string
		| { left?: number | string; right?: number | string }
		| { top?: number | string; bottom?: number | string };
	arrows?: boolean; //default is true;
	pagination?: boolean; //default is true;
	paginationKeyboard?: boolean; //default is true;
	paginationDirection?: 'ltr' | 'rtl' | 'ttb';
	easing?: string; //default is cubic-bezier(0.25, 1, 0.5, 1);
	easingFunc?: (t: number) => number; //default is t => 1 - Math.pow( 1 - t, 4 );
	drag?: boolean | 'free'; //default is true;
	snap?: boolean; //default is false;
	noDrag?: string;
	dragMinThreshold?: number | { mouse: number; touch: number }; //default is 10;
	flickPower?: number; //default is 600
	flickMaxPages?: number; //default is 1
	waitForTransition?: boolean; //default is false
	arrowPath?: string;
	autoplay?: boolean | 'pause'; //default is false
	interval?: number; //default is 5000
	pauseOnHover?: boolean; //default is true
	pauseOnFocus?: boolean; //default is true
	resetProgress?: boolean; //default is true
	lazyLoad?: boolean | 'nearby' | 'sequential'; //default is false
	preloadPages?: number; //default is 1
	keyboard?: boolean | 'global' | 'focused'; //default is false
	wheel?: boolean; //default is false
	wheelMinThreshold?: number;
	wheelSleep?: number;
	releaseWheel?: boolean; //default is false
	direction?: 'ltr' | 'rtl' | 'ttb'; //default is 'ltr'
	cover?: boolean; //default is false
	slideFocus?: boolean;
	focusableNodes?: string; //default is 'a, button, textarea, input, select, iframe'
	isNavigation?: boolean; //default is false
	trimSpace?: boolean | 'move'; //default is true
	omitEnd?: boolean; //default is false
	updateOnMove?: boolean; //default is false
	mediaQuery?: 'min' | 'max'; //default is 'max'
	live?: boolean; //default is true
	breakpoints?: Record<string | number, SplideOptions>;
	reducedMotion?: { speed: number; rewindSpeed: number; autoplay: 'pause' }; //default is {speed: 0,  rewindSpeed: 0,  autoplay: 'pause',};
	classes?: Record<string, string>;
	i18n?: Record<string, string>;
	destroy?: boolean | 'completely'; //default is false
};

export type TCarouselTypeProp = {
	carouselType?: TCarouselType;
};

export type Card = {
	analyticId: string | null;
	type: TCardType;
	title: string;
	actionBar: boolean;
	link: TLink;
	background: {
		image: {
			desktop: {
				url: string;
			};
			mobile: {
				url: string;
			};
		};
		color: string;
		thumbnail: boolean;
	};
	description: string;
	game: {
		id: number;
		slug: string;
		name: string;
		alias: string;
		extId: number;
		description: string | null;
		shortDescription: string;
		graphic_56x56: string | null;
		graphic_592x400: string | null;
		graphic_288x192: string | null;
		graphic_288x152: string | null;
		graphic_288x232: string | null;
		graphic_184x400: string | null;
		icon_graphic_112x112: string | null;
		icon_graphic_128x128: string | null;
		badges: {
			label: string;
			backgroundColor: string;
			labelColor: string;
		} | null;
	};
	size?: 'medium' | 'small';
} & TCarouselTypeProp;

export type RawCarousel = {
	__component: WEB_COMPONENTS_NAMES.Carousel;
	carousels: TCarousel[];
	id: number;
};

export type TCarousel = {
	id: number;
	type: string;
	title: string;
	analyticId: string | null;
	link: TLink;
	cards: Card[];
	bgColor: string;
	infiniteLoop: boolean | null;
	index: number;
};

export type CardAttributes = {
	onMobile?: boolean;
	action_bar: string;
	thumbnail_mode: string;
	link_label: string;
	background_color: string;
	background_image_desktop: string;
	background_image_mobile: string;
	title: string;
	description: string;
	shortDescription: string;
	badge: { label: string; backgroundColor: string; labelColor: string } | null;
	game_slug: string;
	game_alias: string | undefined;
	game_category: string;
	game_name: string;
	game_ext_id: number;
	game_description: string;
	graphic_56x56?: string;
	graphic_592x400?: string;
	graphic_288x192?: string;
	graphic_288x152?: string;
	graphic_288x232?: string;
	graphic_184x400?: string;
	icon_graphic_112x112?: string;
	icon_graphic_128x128?: string;
	// unique from overrides
	override_color_description?: string;
	override_color_overlay?: string;
	override_color_category?: string;
	override_button_normal_border?: string;
	override_button_normal_background?: string;
	override_button_normal_background_end?: string;
	override_button_normal_color?: string;
	override_button_normal_should_use_border?: boolean;
	override_button_hover_border?: string;
	override_button_hover_background?: string;
	override_button_hover_background_end?: string;
	override_button_hover_color?: string;
	override_button_hover_should_use_border?: boolean;
	override_button_active_background?: string;
	override_button_active_background_end?: string;
	override_button_active_color?: string;
	override_button_active_should_use_border?: boolean;
	override_button_focus_background?: string;
	override_button_focus_background_end?: string;
	override_button_focus_color?: string;
	override_button_focus_should_use_border?: boolean;
	override_color_title?: string;
	size?: 'medium' | 'small';
	override_hover_block_toggled?: 'partial' | 'full' | 'none';
	override_hover_block_color_bcg?: string;
	override_hover_block_color_bcg_end?: string;
	override_hover_block_color_text?: string;
	override_hover_block_button_text?: string;
	override_hover_block_button_text_color?: string;
	override_hover_block_button_background_color?: string;
	override_hover_block_button_background_color_end?: string;
	override_hover_block_button_border_color?: string;
	override_hover_block_button_should_use_border?: boolean;
	numeric_card_number?: number;
	assets_versioned_base_url?: string;
	promo_ad?: 'true' | 'false';
	override_is_hover_block?: boolean;
};

export type TOverriddenSlideAttributes = {
	tagName: string;
	attributes: CardAttributes;
	handleClick?: (ev?: any) => void;
	html: string;
};

export const overrideSlideAttributes = (
	c: Card & TCarouselTypeProp,
	cardIndex: number,
	arenaData: any,
	isTablet: boolean,
	isMobile: boolean,
	AITracks: any,
): TOverriddenSlideAttributes | null => {
	let tagName;
	//For Analytics - card click event
	let handleClick;
	// attributes mapping
	const attributesInitial: CardAttributes = {
		onMobile: isMobile,
		action_bar: c?.actionBar?.toString?.(),
		thumbnail_mode: c?.background?.thumbnail?.toString?.(),
		link_label: c?.link?.label || '',
		background_color: c?.background?.color || '',
		background_image_desktop: c?.background?.image?.desktop?.url || '',
		background_image_mobile: c?.background?.image?.mobile?.url || '',
		title: c?.title || '',
		description: c?.description,
		shortDescription: c?.game?.shortDescription || c?.description,
		badge: c?.game?.badges,
		game_slug: c?.game?.slug || '',
		game_alias: c?.game?.alias || '',
		game_category: (c as any)?.game?.category || '', // not passed?
		game_name: c?.game?.name || '',
		game_ext_id: c?.game?.extId || 0,
		game_description: c?.game?.description || '',
		graphic_56x56: c?.game?.graphic_56x56 || '',
		graphic_592x400: c?.game?.graphic_592x400 || '',
		graphic_288x192: c?.game?.graphic_288x192 || '',
		graphic_288x152: c?.game?.graphic_288x152 || '',
		graphic_288x232: c?.game?.graphic_288x232 || '',
		graphic_184x400: c?.game?.graphic_184x400 || '',
		icon_graphic_112x112: c?.game?.icon_graphic_112x112 || '',
		icon_graphic_128x128: c?.game?.icon_graphic_128x128 || '',
		size: c?.size || 'medium',
	};

	let attributes: CardAttributes = { ...attributesInitial } as CardAttributes;
	let attributesOverride: any = {};
	const mergedAttributes = (initial: CardAttributes, attributesOverride: any) => {
		const normalButton = attributesOverride?.button?.find?.((b: { state: string }) => b.state === 'normal');
		const hoverButton = attributesOverride?.button?.find?.((b: { state: string }) => b.state === 'hover');
		const activeButton = attributesOverride?.button?.find?.((b: { state: string }) => b.state === 'active');
		const focusButton = attributesOverride?.button?.find?.((b: { state: string }) => b.state === 'focus');
		return {
			...initial,
			override_color_title: attributesOverride?.title,
			override_color_description: attributesOverride?.description,
			override_color_overlay: attributesOverride?.overla,
			override_color_category: attributesOverride?.category,
			override_button_normal_border: normalButton?.border,
			override_button_normal_background: normalButton?.background,
			override_button_normal_background_end: normalButton?.background_end,
			override_button_normal_should_use_border: normalButton?.should_button_use_border !== false,
			override_button_normal_color: normalButton?.color,
			override_button_hover_border: hoverButton?.border,
			override_button_hover_background: hoverButton?.background,
			override_button_hover_background_end: hoverButton?.background_end,
			override_button_hover_should_use_border: hoverButton?.should_button_use_border !== false,
			override_button_hover_color: hoverButton?.color?.toString,
			override_button_active_border: activeButton?.border,
			override_button_active_background: activeButton?.background,
			override_button_active_background_end: activeButton?.background_end,
			override_button_active_should_use_border: activeButton?.should_button_use_border !== false,
			override_button_active_color: activeButton?.color,
			override_button_focus_border: focusButton?.border,
			override_button_focus_background: focusButton?.background,
			override_button_focus_background_end: focusButton?.background_end,
			override_button_focus_should_use_border: focusButton?.should_button_use_border !== false,
			override_button_focus_color: focusButton?.color,
		};
	};
	const getAnalyticsCardImageUrl = (c: Card, doCheckIfThumbnail = true) =>
		doCheckIfThumbnail && // optional check
		c?.background?.thumbnail // is thumbnail => not full size asset ?
			? c?.game?.graphic_288x192 // thumbnail
			: // or => full size asset
				((typeof window !== 'undefined' && window?.innerWidth && window.innerWidth < 810 // full size bcg asset
					? c?.background?.image?.mobile?.url || c?.background?.image?.desktop?.url // is shown by screen size
					: c?.background?.image?.desktop?.url || c?.background?.image?.mobile?.url) ?? // with fallback to second size
				(c?.background?.image?.desktop?.url || c?.background?.image?.mobile?.url || c.game.graphic_288x192)); // fallback

	switch (c.type) {
		case TCardType.HERO_BIG:
			tagName = 'ark-ui-block-card-hero-big';
			handleClick = (ev: any): void => {
				ev?.stopPropagation?.();
				AITracks.genericTrack({
					eventName: 'heroCard',
					isNonInteraction: false,
					eventAction: AnalyticsEventAction.CLICK,
					customDimensions: {
						cardType: 'HeroCard',
						cardTitle: c?.title ?? c?.game?.name,
						cardImageURL: getAnalyticsCardImageUrl(c, false) ?? c?.game?.graphic_592x400,
					},
				}) as void;
			};
			attributesOverride = arenaData?.layout?.styleOverride?.bigHeroCard || {};
			attributes = mergedAttributes(attributesInitial, attributesOverride);
			return { tagName, attributes, handleClick, html: renderMarkupHeroBig(attributes) };

		case TCardType.STANDARD:
			tagName = 'ark-ui-block-card-standard';
			handleClick = (ev: any): void => {
				ev?.stopPropagation?.();
				AITracks?.genericTrack?.({
					eventName: 'gameCard',
					isNonInteraction: false,
					eventAction: AnalyticsEventAction.CLICK,
					customDimensions: {
						cardType: 'StandardCard',
						cardTitle: c?.title ?? c?.game?.name ?? '',
						cardImageURL: getAnalyticsCardImageUrl(c, true),
						/*badge_text: !isMinimalistic ? badgeText : null,*/
					},
				}) as void;
			};
			attributesOverride = arenaData?.layout?.styleOverride?.standardCard || {};
			attributes = {
				...attributesInitial,
				override_color_title: attributesOverride?.title?.toString() || '',
				override_hover_block_toggled: getStandardCardHoverBlockType(
					isTablet || isMobile,
					attributesOverride?.hoverBlock?.type,
				),
				override_hover_block_color_bcg: attributesOverride?.hoverBlock?.background || '',
				override_hover_block_color_text: attributesOverride?.hoverBlock?.color || '',
				override_hover_block_button_text: attributesOverride?.hoverBlock?.buttonText || 'Play',
				override_hover_block_button_text_color: attributesOverride?.hoverBlock?.buttonTextColor,
				override_hover_block_button_should_use_border:
					attributesOverride?.hoverBlock?.should_button_use_border !== false,
				override_hover_block_button_background_color: attributesOverride?.hoverBlock?.buttonBackground,
				override_hover_block_button_background_color_end: attributesOverride?.hoverBlock?.buttonBackground_end,
				override_hover_block_button_border_color: attributesOverride?.hoverBlock?.buttonBorderColor,
			};
			return { tagName, attributes, handleClick, html: renderMarkupStandard(attributes) };

		case TCardType.HERO_SEO:
			tagName = 'ark-ui-block-card-hero-seo';
			handleClick = (ev: any): void => {
				ev?.stopPropagation?.();
				AITracks?.genericTrack?.({
					eventName: 'heroCard',
					isNonInteraction: false,
					eventAction: AnalyticsEventAction.CLICK,
					customDimensions: {
						cardType: 'SeoCard',
						cardTitle: c?.title,
					},
				}) as void;
			};
			attributesOverride = arenaData?.layout?.styleOverride?.seoCard ?? {};
			attributes = {
				...attributesInitial,
				background_color: c.background?.color,
				override_color_title: attributesOverride?.title,
				override_color_description: attributesOverride?.description,
				override_button_normal_color: attributesOverride?.button?.[0]?.color,
			};
			return { tagName, attributes, handleClick, html: renderMarkupHeroSeo(attributes) };

		case TCardType.HERO_MKT:
			tagName = 'ark-ui-block-card-hero-marketing';
			handleClick = (ev: any): void => {
				ev?.stopPropagation?.();
				AITracks?.genericTrack?.({
					eventName: 'heroCard',
					isNonInteraction: false,
					eventAction: AnalyticsEventAction.CLICK,
					customDimensions: {
						cardType: 'MarketingCard',
						cardTitle: c?.description,
						cardImageURL: getAnalyticsCardImageUrl(c, false),
						/*badge_text: !isMinimalistic ? badgeText : null,*/
					},
				}) as void;
			};
			attributesOverride = arenaData?.layout?.styleOverride?.marketingCard ?? {};
			attributes = mergedAttributes(attributesInitial, attributesOverride);
			return { tagName, attributes, handleClick, html: renderMarkupHeroMarketing(attributes) };

		case TCardType.NUMERIC:
			tagName = 'ark-ui-block-card-mod-numeric';
			handleClick = (ev: any): void => {
				ev?.stopPropagation?.();
				AITracks?.genericTrack?.({
					eventName: 'gameCard',
					isNonInteraction: false,
					eventAction: AnalyticsEventAction.CLICK,
					customDimensions: {
						cardType: 'NumericCard',
						cardTitle: c?.title ?? c?.game?.name ?? '',
						cardNumber: cardIndex + 1,
						cardImageURL: getAnalyticsCardImageUrl(c),
					},
				}) as void;
			};
			attributesOverride = arenaData?.layout?.styleOverride?.standardCard || {};
			attributes = {
				...attributesInitial,
				override_color_title: attributesOverride?.description || '',
				override_hover_block_toggled: getStandardCardHoverBlockType(
					isTablet || isMobile,
					attributesOverride?.hoverBlock?.type,
				),
				override_hover_block_color_bcg: attributesOverride?.hoverBlock?.background,
				override_hover_block_color_text: attributesOverride?.hoverBlock?.color,
				override_hover_block_button_text: attributesOverride?.hoverBlock?.buttonText || 'Play',
				override_hover_block_button_text_color: attributesOverride?.hoverBlock?.buttonTextColor,
				override_hover_block_button_should_use_border:
					attributesOverride?.hoverBlock?.should_button_use_border !== false,
				override_hover_block_button_background_color: attributesOverride?.hoverBlock?.buttonBackground,
				override_hover_block_button_background_color_end: attributesOverride?.hoverBlock?.buttonBackground_end,
				override_hover_block_button_border_color: attributesOverride?.hoverBlock?.buttonBorderColor,
				numeric_card_number: cardIndex + 1,
				assets_versioned_base_url: `${UIKIT_CONSTS.urlBase}/${
					arenaData?.themeLayout?.webKitVersion || UIKIT_CONSTS.urlVersionDefault
				}/`,
			};
			return { tagName, attributes, handleClick, html: renderMarkupNumeric(attributes) };

		case TCardType.PORTRAIT:
			tagName = 'ark-ui-block-card-portrait';
			handleClick = (ev: any): void => {
				ev?.stopPropagation?.();
				AITracks?.genericTrack?.({
					eventName: 'gameCard',
					isNonInteraction: false,
					eventAction: AnalyticsEventAction.CLICK,
					customDimensions: {
						cardType: 'PortraitCard',
						cardTitle: c?.title ?? c?.game?.name ?? '',
						cardImageURL: c?.background?.image?.desktop?.url || c?.game?.graphic_184x400,
						/*badge_text: !isMinimalistic ? badgeText : null,*/
					},
				}) as void;
			};
			attributesOverride = arenaData?.layout?.styleOverride?.portraitCard || {};
			attributes = {
				...attributesInitial,
				override_color_title: attributesOverride?.title?.toString(),
				override_hover_block_toggled: attributesOverride?.hoverBlock?.type,
				override_hover_block_color_bcg: attributesOverride?.hoverBlock?.background,
				override_hover_block_color_text: attributesOverride?.hoverBlock?.color,
				override_hover_block_button_text: attributesOverride?.hoverBlock?.buttonText || 'Play',
				override_hover_block_button_text_color: attributesOverride?.hoverBlock?.buttonTextColor,
				override_hover_block_button_should_use_border:
					attributesOverride?.hoverBlock?.should_button_use_border !== false,
				override_hover_block_button_background_color: attributesOverride?.hoverBlock?.buttonBackground,
				override_hover_block_button_background_color_end: attributesOverride?.hoverBlock?.buttonBackground_end,
				override_hover_block_button_border_color: attributesOverride?.hoverBlock?.buttonBorderColor,
			};
			return { tagName, attributes, handleClick, html: renderMarkupPortrait(attributes) };

		default:
			tagName = '';
			break;
	}

	if (!tagName) {
		return null;
	}

	return { tagName, attributes, handleClick, html: '' };
};

const heroCarouselCardTypes = [TCardType.HERO_BIG, TCardType.HERO_MKT, TCardType.HERO_SEO]; //not used

export function getCarouselTypeByCardsList(cardsList: Card[]): TCarouselType {
	const cardsTypedList: TCardType[] = (cardsList || []).map((card: Card) => card.type);
	let carouselType: TCarouselType = TCarouselType.UNKNOWN;
	try {
		// any additional checks for existing cards or new card types can be added here
		switch (true) {
			case cardsTypedList.some(
				(cardType) =>
					cardType === TCardType.HERO_BIG ||
					cardType === TCardType.HERO_MKT ||
					cardType === TCardType.HERO_SEO,
			):
				carouselType = TCarouselType.HERO;
				break;
			case cardsTypedList.every((cardType) => cardType === TCardType.STANDARD):
				carouselType = TCarouselType.STANDARD;
				break;
			case cardsTypedList.every((cardType) => cardType === TCardType.NUMERIC):
				carouselType = TCarouselType.NUMERIC;
				break;
			case cardsTypedList.every((cardType) => cardType === TCardType.FEATURED):
				carouselType = TCarouselType.FEATURED;
				break;
			case cardsTypedList.every((cardType) => cardType === TCardType.FEATURED_SMALL):
				carouselType = TCarouselType.FEATURES_SMALL;
				break;
			case cardsTypedList.every((cardType) => cardType === TCardType.PORTRAIT):
				carouselType = TCarouselType.PORTRAIT;
				break;
			default:
				carouselType = TCarouselType.UNHANDLED;
				break;
		}
	} catch (e) {
		console.error('Carousel type detection error: ', e);
	}

	return carouselType;
}

export function getStandardCardHoverBlockType(
	isTabletOrMobile: boolean,
	hoverOverrideType: 'none' | 'full' | 'partial' | null | undefined,
): 'none' | 'full' | 'partial' {
	return isTabletOrMobile && hoverOverrideType && hoverOverrideType !== 'none'
		? 'full' //EVO-727 mobile hover block is always full if not turned off
		: hoverOverrideType || 'none'; // desktop hover block
}
