/* eslint-disable react-hooks/rules-of-hooks */
import { TCardPropsCMS } from '../../_core/TypesCardPropsCMS';
import { atomThumbnailIconMarkup } from '../../element_atoms/thumbnail-icon';
import { CSS_BREAKPOINT_USED } from '../../global_styles/_breakpoints';
import { atomCardBadgeMarkup } from '../../element_atoms/card-badge';
import { getBooleanAttr } from '../../helper_funcs/getBooleanAttr';
import { generateCardId } from '../../helper_funcs/generateCardId';
import { TSTStateCalltoaction, atomCallToActionMarkup } from '../../element_atoms/call-to-action';
import { hexToRgb } from '../../helper_funcs/hexToRgb';
import { ETabIndexesOrderingLevels } from '@/types/types';
export type TSTState = TCardPropsCMS | any;
export const defaultState: TSTState = {
  variation: 'c',
  size: '',
  // "small" | "medium"
  action_bar: 'true',
  thumbnail_mode: 'true',
  link_label: 'Play',
  background_color: '#542C8A',
  background_image_desktop: '',
  background_image_mobile: '',
  title: "The Independent's Jumbo General Knowledge Crossword",
  description: 'Play and win $5 reward',
  shortDescription: '',
  badge: {
    label: 'New',
    backgroundColor: '#FFFFFF',
    labelColor: '#000000'
  },
  game_slug: 'bubble-shooter',
  game_category: 'Casual',
  game_name: 'Crystal Collapse Crystal Collapse Crystal Collapse',
  game_ext_id: 0,
  game_description: 'Crystal Collapse: cool game about crystals Crystal Collapse: cool game about crystals Crystal Collapse: cool game about crystals Crystal Collapse: cool game about crystals',
  // OVERRIDES FOR HERO & STANDARD
  override_color_title: '',
  // seoCard standardCard
  override_hover_block_toggled: 'partial',
  override_hover_block_color_bcg: '',
  override_hover_block_color_text: '',
  override_hover_block_button_text: 'Play',
  override_hover_block_button_text_color: '',
  override_hover_block_button_background_color: '',
  override_hover_block_button_background_color_end: '',
  override_hover_block_button_border_color: '',
  icon_graphic_112x112: '',
  graphic_288x192: '',
  graphic_288x152: ''
};
export function renderMarkup(passedState: TSTState = null, window = {}) {
  // @ts-ignore
  const _this = this;
  const state = passedState;
  const id = generateCardId('ark-ui-block-card-standard');
  /* USED PROPERTIES MAPPING */
  const bgColor: string = state?.background_color || `var(--color-white)`;
  const bgImageDesk: string = state?.background_image_desktop || state?.graphic_288x192 || '';
  const bgImageMob: string = state?.background_image_mobile || '';
  const actionBarToggle: boolean = getBooleanAttr('action_bar', {
    ...state
  }, _this);
  const thumbnailModeToggle: boolean = getBooleanAttr('thumbnail_mode', {
    ...state
  }, _this);
  const title: string = state?.title || state?.game_name || '';
  const iconThumbnailModern = state?.icon_graphic_112x112 || '';
  const imgAltText = state?.title ? state?.title : state?.game_name || 'Card icon';
  const accessibilityLabel = ` aria-label="${state?.title || state?.game_name || 'Card title'}"`;
  const badge: {
    label: string;
    backgroundColor: string;
    labelColor: string;
  } | null | undefined = state?.badge ?? null;
  /* STATE VARIATIONS */
  const variation = state?.variation || ''; // for local testing purposes, not real attribute passed
  const cardSize = state?.size || (variation === 'c' || variation === 'e' ? 'small' : 'medium');
  const imgCoverMobile: string = bgImageMob || bgImageDesk || '';
  const imgCoverDesktop: string = bgImageDesk || bgImageMob || '';
  const isActionBar = actionBarToggle && variation !== 'a' && variation !== 'c' && variation !== 'e' || variation === 'b' || variation === 'd';
  const isThumbnail = thumbnailModeToggle && variation !== 'a' && variation !== 'd' && variation !== 'e' || variation === 'b' || variation === 'c';
  const isFullAsset = !isThumbnail;
  const isVariationA = variation === 'a' || cardSize === 'medium' && isFullAsset && !isActionBar; // medium size standard card + full image asset + without action bar → variation A
  const isVariationB = variation === 'b' || cardSize === 'medium' && !isFullAsset && isActionBar; // medium size standard card + game icon + with action bar → variation B
  const isVariationC = variation === 'c' || cardSize === 'small' && !isFullAsset && !isActionBar; // small size standard card + game icon left + right title → variation C
  const isVariationD = variation === 'd' || cardSize === 'medium' && isFullAsset && isActionBar; // medium size standard card + full image asset + with action bar → variation D
  const isVariationE = variation === 'e' || cardSize === 'small' && isFullAsset && !isActionBar; // small size standard card + full image asset → variation E
  let dynamicClasses = `${isActionBar ? ' __action-bar' : ''}${isFullAsset && !isActionBar ? ' __full_asset' : ''}`;
  switch (true) {
    // OLD ONES
    case isVariationA:
      // medium size standard card + full image asset + without action bar → variation A
      dynamicClasses = ` __medium __full_asset __a`; // no __action-bar
      break;
    case isVariationB:
      // medium size standard card + game icon + with action bar → variation B
      dynamicClasses = ` __medium __action-bar __b`; // no __full_asset
      break;
    case isVariationC:
      // small size standard card + game icon left + right title → variation C
      dynamicClasses = ` __small __c`; // no __full_asset, no __action-bar => thumbnail, default
      break;
    // NEW ONES
    case isVariationD:
      // medium size standard card + full image asset + with action bar → variation D
      dynamicClasses = ` __medium __full_asset __action-bar __d`;
      break;
    case isVariationE:
      // small size standard card + full image asset → variation E
      dynamicClasses = ` __small __full_asset __e`;
      break;
    // FALLBACK TO BASIC DESIGN
    default:
      break;
  }
  /* MODIFICATIONS COMBOS */
  const overrideTitleColor = state?.override_color_title || '';
  const overrideTitleColorStyle = !overrideTitleColor ? '' : ` style="color: ${overrideTitleColor} !important;`;
  const useTitleRender = () => `<p class="card_title"${overrideTitleColorStyle}${accessibilityLabel}"><span>${title}</span></p>`;
  const useFullAssetRender = () => isFullAsset // thumbnail mode here is "icon + title, not full asset"
  ? `
        ${imgCoverMobile && state?.onMobile ? `<img src="${imgCoverMobile}" alt="${imgAltText}" class="card_cover __mobile" loading="lazy"/>` : ''}
        ${imgCoverDesktop && !state?.onMobile ? `<img src="${imgCoverDesktop}" alt="${imgAltText}" class="card_cover __desktop" loading="lazy"/>` : ''}
    ` : '';
  const gameTitle = state?.game_name || '';
  const gameDescr = state?.shortDescription || '';
  const gameSlug = state?.game_slug || '';
  /* FULL HOVER BUTTON */
  const btnText: string = state?.override_hover_block_button_text || '';
  const isGradientButton = state?.override_hover_block_button_background_color_end && state?.override_hover_block_button_background_color_end !== state?.override_hover_block_button_background_color;
  const btnStateBasic: TSTStateCalltoaction = {
    'text': btnText,
    'tabIndex': -1,
    'override_button_normal_color': state?.override_hover_block_button_text_color,
    'override_button_normal_background': state?.override_hover_block_button_background_color,
    'override_button_normal_background_end': state?.override_hover_block_button_background_color_end,
    'override_button_normal_border': state?.override_hover_block_button_border_color,
    'override_button_normal_should_use_border': state?.override_button_normal_should_use_border,
    'override_button_hover_background': state?.override_hover_block_button_background_color,
    'override_button_hover_background_end': state?.override_hover_block_button_background_color_end,
    'override_button_active_background': state?.override_hover_block_button_background_color,
    'override_button_active_background_end': state?.override_hover_block_button_background_color_end,
    ...(!isGradientButton ? {} : {
      override_button_hover_should_use_border: true,
      override_button_hover_border: state?.override_hover_block_button_border_color,
      override_button_active_should_use_border: true,
      override_button_active_border: state?.override_hover_block_button_border_color
    }),
    'override_is_hover_block': true,
    'icon-url': '/assets/play.svg',
    'icon-position': 'before',
    'size': 'small'
  };
  const btnStateThemeDark: TSTStateCalltoaction = {
    ...btnStateBasic,
    styling: 'outline',
    addClass: `__dark`,
    addToId: '__dark'
  };
  const hoverBlockMarkup = () => {
    switch (state?.override_hover_block_toggled) {
      case 'partial':
        return `
                    <div class="card_hover-block" style="background-color: ${state?.override_hover_block_color_bcg ? hexToRgb(state?.override_hover_block_color_bcg) + ` , 0.7)` : 'rgba(0, 0, 0, 0.7)'};">
                        <p id="card_hover-block_title_${gameSlug}" class="card_hover-block_title" style="color: ${state?.override_hover_block_color_text || 'var(--color-white)'};">${gameTitle}</p>
                        <p id="card_hover-block_description_${gameSlug}" class="card_hover-block_description" style="color: ${state?.override_hover_block_color_text || 'var(--color-white)'};">${gameDescr}</p>
                    </div>
                `;
      case 'full':
        return `
                    <div class="card_hover-block __full" style="background-color: ${state?.override_hover_block_color_bcg ? hexToRgb(state?.override_hover_block_color_bcg) + ` , 0.7)` : 'rgba(0, 0, 0, 0.7)'};">
                    <div class="card_hover-block_content">
                        <div>
                            <p id="card_hover-block_title_${gameTitle}" class="card_hover-block_title" style="color: ${state?.override_hover_block_color_text || 'var(--color-white)'};">${gameTitle}</p>
                            <p id="card_hover-block_description_${gameDescr}" class="card_hover-block_description" style="color: ${state?.override_hover_block_color_text || 'var(--color-white)'};">${gameDescr}</p>
                        </div>
                        <div class="card_hover-block_button">
                            ${atomCallToActionMarkup(btnStateThemeDark)}
                        </div>
                    </div>
                    </div>
                `;
      default:
        return '';
    }
  };
  /* RENDER */

  //${atomThumbnailIconMarkup(iconThumbnailMinimalistic, 'card_icon __minimalistic', `${imgAltText} icon`, undefined, undefined, 'margin-right: .5rem;')}
  const fullCardBorder = state?.override_hover_block_toggled === 'none' || state?.override_hover_block_toggled === 'partial';

  // setting tabinedex -1 for div, since splice accessibility says we cant use tabindex 0 for elements that has aria hidden true and handles automatically
  const html = `
        ${generateDynamicStyles()}
        ${badge && badge?.backgroundColor && badge?.label && atomCardBadgeMarkup(badge?.label, badge?.backgroundColor, badge?.labelColor)}
        <div id="${id}" class="card ${dynamicClasses} ${fullCardBorder ? 'no_content_focus' : ''}" style="background-color:${bgColor};" tabindex="${ETabIndexesOrderingLevels.NOINTERACTION}">
            ${atomThumbnailIconMarkup(iconThumbnailModern, 'card_icon __modern', `${imgAltText} icon`, undefined, undefined, 'margin-right: .5rem;', isThumbnail)}     
            ${useTitleRender()}
            ${useFullAssetRender()}
            ${hoverBlockMarkup()}
        </div>
    `;
  return html;

  // DYNAMIC STYLES
  function generateDynamicStyles() {
    return `
            <style>
                /* modern card - with actionbar, mobile, medium */
                #${id}.__action-bar {
                    --card-standard-padding-v: .5rem;
                    --card-standard-padding-h: .5rem;
                    --card-standard-actionbar-height: 1.5rem;
                }
                /* modern card - with actionbar, desktop */
                @media (min-width: ${CSS_BREAKPOINT_USED()}px) {
                    #${id}.__action-bar {
                        --card-standard-padding-v: 1.25rem;
                        --card-standard-padding-h: 1rem;
                        --card-standard-actionbar-height: 2.5rem;
                    }
                }
                /* minimalistic card - always actionbar */
                [data-mode="minimalistic"] #${id} {
                    --card-standard-padding-v: .25rem;
                    --card-standard-padding-h: .5rem;
                    --card-standard-actionbar-height: 1.5rem;
                }
                /* modern card - with actionbar, desktop */
                @media (min-width: ${CSS_BREAKPOINT_USED()}px) {
                    [data-mode="minimalistic"] #${id} {
                        --card-standard-padding-v: .5rem;
                        --card-standard-padding-h: 1rem;
                        --card-standard-padding: .5rem 1rem;
                        --card-standard-actionbar-height: 2.5rem;
                    }
                }
            </style>
        `;
  }
}